:root {
  background-color: var(--neutral-theme);
}

.pageRoot {
  width: 100vw;
}

.pageSlider-on {
  transition: all 600ms;
}

.pageSlider-off {
  transition: unset;
}

.pageSlider-left {
  transform: translateX(100vw);
}

.pageSlider-right {
  transform: translateX(-100vw);
}

.pageSlider-end {
  transform: unset;
}
